<template>
    <div class="notice">
        <h2 class="noticeTitle" :style="{ textAlign: titleLeft ? 'left' : 'center' }">重要通知</h2>
        <div class="flex">
          <div class="noticeContent" :class="{ hideContent: openShowMore }" v-if="context">
            <label class="showMore clickBtn" v-if="openShowMore" @click="openShowMore = !openShowMore, openPickUp = !openPickUp"><span>...</span>查看全部</label>
            {{ context }}
            <span v-if="openPickUp" @click="openShowMore = !openShowMore, openPickUp = !openPickUp" class="clickBtn openPickUp">收起</span>
          </div>
          <div v-else>暂无通知</div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'notice',
  props: {
    titleLeft: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      contentHeight: 0,
      openShowMore: false,
      openPickUp: false,
      context: ''
    }
  },
  mounted() {
    this.getTips()
  },
  methods: {
    getTips() {
      this.$api.getTips({ label: this.label }).then(res => {
        this.context = res.tip
        this.$nextTick(() => {
          if (this.context) {
            this.getContentHeight()
          }
        })
      })
    },
    getContentHeight() {
      var noticeContent = document.querySelector('.noticeContent')
      if (noticeContent.clientHeight > 40) {
        this.openShowMore = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notice {
    text-align: center;
    width: 100;
    min-height: 102px;
    transition: height .3s ease;

    .noticeTitle {
        color: #333;
    }

    .noticeContent {
        font-size: 16px;
        text-align: left;
        line-height: 20px;
        word-break: break-all;
    }
    .hideContent {
      height: 40px;
      overflow: hidden;
    }
    .noticeContent::before {
      content: '';
      float: right;
      height: 100%;
      width: 0;
      margin-bottom: -20px;
    }
    .showMore {
        float: right;
        clear: both;
        position: relative;
        span {
          margin-right: 10px;
          color: #606266;
          // letter-spacing: 2px;
        }
    }
    .openPickUp {
      margin-left: 10px;
    }
    .clickBtn {
        cursor: pointer;
        color: #409EFF;
    }
}
</style>
