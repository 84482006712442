<template>
  <div class="content">
        <notice :title-left="true" label="write_page_tips"/>
        <div class="fillingArea flex">
          <div class="area">
            <el-form label-position="top" :model="userForm" ref="userForm" :rules="rules">
              <el-form-item label="用户名" prop="username">
                <div class="input"><el-input v-model="userForm.username"></el-input></div>
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <div class="input"><el-input v-model="userForm.mobile"></el-input></div>
              </el-form-item>
              <el-form-item label="联系人邮箱" prop="email">
                <div class="input"><el-input v-model="userForm.email"></el-input></div>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <div class="input"><el-input type="textarea" v-model="userForm.note"></el-input></div>
              </el-form-item>
            </el-form>
          </div>
          <div class="area">
            <div class="areaTitle">请选择套餐</div>
            <div class="packageList">
              <div class="package flex_ac" v-for="item in mealList" :key="item.id" @click="userForm.setmeal_id = item.id">
                <span class="circle flex_jc_ac">
                  <span v-if="userForm.setmeal_id === item.id"></span>
                </span>
                <span class="packageName">{{  item.name  }}</span>
                <img class="query" src="../assets/images/wenhao.png" alt="">
                <span class="price">￥{{ item.price }}</span>
                <div class="packgeContent">{{ item.describe }}</div>
              </div>
            </div>
            <div class="button">
              <el-button type="primary" class="buttonClass" @click="submitForm('userForm')" :loading="loading">提交订单</el-button>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { type } from 'os'
import { basename } from 'path'
import notice from '../components/Notice.vue'

export default {
  name: 'orderFilling',
  components: {
    notice
  },
  data() {
    var validatereEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系人邮箱！'))
      } else if (!this.isEmailAvailable(value)) {
        callback(new Error('请输入正确的邮箱！'))
      } else {
        callback()
      }
    }
    var validaterePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码！'))
      } else if (!this.checkPhone(value)) {
        callback(new Error('请输入正确的手机号码！'))
      } else {
        callback()
      }
    }
    return {
      userForm: {
        setmeal_id: null,
        mobile: '',
        username: '',
        email: '',
        note: ''
      },
      rules: {
        mobile: [{ validator: validaterePhone, trigger: 'blur', required: true }],
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        email: [{ validator: validatereEmail, required: true, trigger: 'blur' }]
      },
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      mealList: [],
      loading: false // 套餐列表
    }
  },
  created() {
    Object.keys(this.userForm).forEach(item => {
      this.userForm[item] = this.userInfo[item]
    })
    this.setmealList()
  },
  methods: {
    isEmailAvailable(obj) {
      const str = new RegExp('^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$')
      if (!str.test(obj)) {
        return false
      }
      return true
    },
    checkPhone(phone) {
      if (!(/^1[3456789]\d{9}$/.test(phone))) {
        return false
      } else {
        return true
      }
    },
    submitForm(formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$api.submitOrder(this.userForm).then(res => {
            this.loading = false
            Object.keys(that.userForm).forEach(val => {
              if (val !== 'setmeal_id') {
                that.userForm[val] = ''
              }
            })
            that.$message({
              message: '创建新订单成功！',
              type: 'success'
            })
            setTimeout(() => {
              that.$router.replace({
                path: '/home/orderSubmit',
                query: { order_num: res.order_num }
              })
            }, 1000)
          }).catch(() => {
            that.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    setmealList() {
      this.$api.setmealList().then(res => {
        this.mealList = res.list
        this.userForm.setmeal_id = res.list[0].id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 30px;
  background-color: #fff;
  padding: 20px;
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    border: 1px solid #e3e3e3;
    .fillingArea {
      margin-top: 10px;
      width: 100%;
      .area {
        flex: 1;
        .input {
          width: 450px;
        }
        .areaTitle {
          font-size: 14px;
          line-height: 40px;
          padding-bottom: 10px;
        }
        .packageList {
          height: 320px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .package {
            position: relative;
            cursor: pointer;
            margin-bottom: 33px;
            &:last-child {
              margin-bottom: 250px;
            }
            span {
              &.circle {
                width: 12px;
                height: 12px;
                border: 1px solid #409EFF;
                border-radius: 50%;
                span {
                  width: 8px;
                  height: 8px;
                  background: #409EFF;
                  border-radius: 50%;
                }
              }
              &.packageName {
                color: #333;
                font-weight: bold;
                margin-left: 10px;
              }
            }
            .packgeContent {
              width: 400px;
              padding: 12px;
              background-color: #fff;
              position: absolute;
              bottom: 0;
              transform: translateY(100%);
              z-index: 999;
              // right: -174px;
              // top: -12px;
              box-shadow: 0px 4px 4px 0px #F1F1F1;
              border-radius: 4px;
              display: none;
            }
            .query {
              margin-left: 5px;
              width: 14px;
              height: 14px;
              &:hover ~ .packgeContent {
                display: block;
              }
            }
            .price {
              color: #409EFF;
              font-weight: 600;
              margin-left: 10px;
            }
          }
        }
        .button {
          margin-top: 20px;
          .buttonClass {
            width: 200px;
            height: 48px;
          }
        }
      }
    }
  }
}

</style>
